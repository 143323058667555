import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCheapestHardwareWithTariff } from '../../../helpers/entity';
import { bindQueryParams } from '../../../helpers/url';

import connectUI from '../../basics/ui/UIConnector';
import suitcss from '../../../helpers/suitcss';
import { list as hardwareListShape, shape as hardwareShape } from '../../../propTypes/hardware';
import {
  ORDER_STATE_UNAVAILABLE,
  ORDER_STATE_AVAILABLE,
} from '../../../propTypes/common';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import TextMessage from '../../basics/text/TextMessage';
import MediaImage from '../../basics/media/MediaImage';
import HardwareSelector from './HardwareSelector';
import Callout from '../../../containers/callout/Callout';
import imageSizes from '../../../config/imageSizes';
import { createWhatsAppShareLink } from '../../../helpers/socialMediaLinkShare';

import HardwarePaymentUnit from '../../../containers/hardware/HardwarePaymentUnit';
import Tag from '../../basics/text/Tag';
import {
  DIALOG_ID_SHIPPING_FREE_INFO,
  DIALOG_ID_SHIPPING_INFO,
  ICON_WHATSAPP_SHARE_BUTTON_PNG,
  PAYMENT_FEE_MONTHLY,
  PAYMENT_FEE_SINGLE,
  QUERY_SELECTED_TARIFF,
} from '../../../helpers/constants';
import { showTemplateDialog } from '../../../actions/page/dialog';
import HardwareDeliveryState from './HardwareDeliveryState';

class HardwareItemFull extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.toggleSelect = this.toggleSelect.bind(this);
  }

  getSelectedHardware() {
    const { hardwareList, selectedHardware } = this.props;
    return selectedHardware || hardwareList[0];
  }

  toggleSelect() {
    const { onBeforeSelect, dispatch, urlSelect, selectedTariffId } = this.props;
    const selectedHardware = this.getSelectedHardware();
    const entityValues = { eid: selectedHardware.eid, etype: selectedHardware.etype };
    onBeforeSelect(entityValues);
    if (selectedTariffId) {
      dispatch(push(urlSelect));
    }
  }

  renderSelector(displayAsOption, utilities) {
    const { hardwareList, onChange } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return (
      <div
        className={suitcss({
          descendantName: 'selector',
          utilities,
        }, this)}
      >
        <HardwareSelector
          theme={displayAsOption ? 'full' : 'compact'}
          hardwareList={hardwareList.filter(item => item.orderState !== ORDER_STATE_UNAVAILABLE)}
          selectedHardware={selectedHardware}
          onChange={onChange}
        />
      </div>
    );
  }

  renderPrices(utilities) {
    const { ui, selectedTariffId, dispatch, shippingFee } = this.props;
    const selectedHardware = this.getSelectedHardware();
    return (
      <div
        className={suitcss({
          descendantName: 'prices',
          utilities,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'singlePaymentFee',
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'smBlock'],
            }, this)}
          >
            <HardwarePaymentUnit
              hardware={selectedHardware}
              tariffId={selectedTariffId}
              paymentType={PAYMENT_FEE_SINGLE}
              postfix={ui.guiWordSingular}
              size="m"
            />
          </div>
          <div
            className={suitcss({
              descendantName: 'unit',
              utilities: ['hidden', 'lBlock'],
            }, this)}
          >
            <HardwarePaymentUnit
              hardware={selectedHardware}
              tariffId={selectedTariffId}
              paymentType={PAYMENT_FEE_SINGLE}
              postfix={ui.guiWordSingular}
              size="l"
            />
          </div>
        </div>
        <div
          className={suitcss({
            descendantName: 'paymentFee',
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'unit',
            }, this)}
          >
            <HardwarePaymentUnit
              hardware={selectedHardware}
              tariffId={selectedTariffId}
              paymentType={PAYMENT_FEE_MONTHLY}
              prefix={ui.guiSymbolPlus}
              postfix={ui.guiFeeExtraMonthlyAbbr}
            />
          </div>
        </div>
        <div
          className={suitcss({
            descendantName: 'copy',
            utilities: ['colorGray100'],
            modifiers: ['delivery'],
          }, this)}
        >
          <Copy embedded>
            <HardwareDeliveryState selectedHardware={selectedHardware}/>
          </Copy>
          <Copy
            utilities={['smMarginBottomS', 'inline', 'marginRightXXS']}
            embedded
            raw
          >
            {ui.guiShippingCosts}
          </Copy>
          <Link
            className={suitcss(
              { descendantName: 'infoIcon' },
              this,
            )}
            element="button"
            onClick={() => dispatch(showTemplateDialog(
              shippingFee.unit > 0 ? DIALOG_ID_SHIPPING_INFO : DIALOG_ID_SHIPPING_FREE_INFO,
              ))}
            withoutArrow
            withoutStyle
            icon="/icons/content-info.svg"
            size="small"
            ariaLabel={ui.guiShippingCostsInfoIAlt}
          />
        </div>
      </div>
    );
  }

  renderButtons(utilities = []) {
    const {
      ui,
      onBeforeSelect,
      selectedTariffId,
      selectedHardware,
      isContractRenewal,
    } = this.props;
    const cheapestHardwareWithTariff = !selectedTariffId && !isContractRenewal
      && selectedHardware && getCheapestHardwareWithTariff(selectedHardware, selectedTariffId, ui);
    const urlSelect = bindQueryParams(this.props.urlSelect, {
      ...(cheapestHardwareWithTariff && {
        [QUERY_SELECTED_TARIFF]: cheapestHardwareWithTariff,
      }),
    });
    const whatAppLink = createWhatsAppShareLink();
    // select should render a button/ should not render a anchor if onBeforeSelect is defined
    const selectAsButton = (typeof onBeforeSelect === 'function');
    return (
      <div className={suitcss({ descendantName: 'buttons', utilities }, this)}>
        <div
          className={suitcss({
            descendantName: 'button',
          }, this)}
        >
          <Link
            to={urlSelect}
            element={selectedTariffId ? 'button' : null}
            onClick={selectAsButton ? this.toggleSelect : null}
            asButton
            buttonFilled
          >
            {selectedTariffId && (
              ui.guiAddCart
            )}
            {!selectedTariffId && (
              ui.guiContinueTariff
            )}
          </Link>
        </div>
        {/* removed button for OP-2276
       {selectedHardware.vvl &&
          <div className={suitcss({ descendantName: 'button' }, this)} >
            <Link
              onClick={e => { e.preventDefault(); onContractRenewal(selectedHardware); }}
              asButton
            >
              {ui.myVvlButtonStart}
            </Link>
          </div>
        } */}
        <div className={suitcss({ descendantName: 'button' }, this)} >
          <Link
            asShareButton
            utilities={['marginTop']}
            href={whatAppLink}
          >
            <MediaImage src={ICON_WHATSAPP_SHARE_BUTTON_PNG} />
            {ui.guiButtonWhatsapp}
          </Link>
        </div>
      </div>
    );
  }

  renderAccessory(utilities = []) {
    const { accessory } = this.props;
    return (
      <div className={suitcss({ descendantName: 'accessory', utilities }, this)}>
        {accessory.name && (<Headline size="m">+ {accessory.name}</Headline>)}
        {accessory.image && (<MediaImage {...accessory.image} isLazy />)}
      </div>
    );
  }

  render() {
    const {
      name,
      description,
      onToggleGallery,
      ui,
      primaryModule,
      isContractRenewal,
      accessory,
      asTeaser,
      isNew,
    } = this.props;
    const selectedHardware = this.getSelectedHardware();
    const isAvailable = selectedHardware.orderState === ORDER_STATE_AVAILABLE;
    const hardwareImage = {
      ...selectedHardware.image,
      sizes: imageSizes.hardwareItemFull,
    };
    return (
      <div
        className={suitcss({}, this)}
        data-tracking="hardware"
        data-hardware-id={selectedHardware.iid}
      >
        <div
          className={suitcss({
            descendantName: 'visuals',
          }, this)}
        >
          <Callout
            theme="badge"
            targets={[selectedHardware, selectedHardware.groupId]}
          />
          <div className={suitcss({ descendantName: 'image' }, this)}>
            <MediaImage {...hardwareImage} isLazy emptyAlt />
          </div>
          <div
            className={suitcss({
              descendantName: 'galleryLink',
            }, this)}
          >
            <Link
              element="button"
              onClick={onToggleGallery}
              withoutArrow
            >
              {ui.hdeViewGallery}
            </Link>
          </div>

        </div>
        <div className={suitcss({ descendantName: 'content' }, this)}>
          <div className={suitcss({ descendantName: 'mainContent' }, this)}>
            <Callout
              theme="label"
              targets={[selectedHardware, selectedHardware.groupId]}
            />
            <div className={suitcss({ descendantName: 'headline' }, this)}>
              <Headline size="l" embedded element={primaryModule ? 'h1' : 'h3'}>
                {name}
                {isNew &&
                  <React.Fragment>
                    &nbsp;
                    <Tag theme="tertiary" label={ui.guiWordNew} />
                  </React.Fragment>
                }
              </Headline>
            </div>
            <div
              className={suitcss({
                descendantName: 'description',
                utilities: ['hidden', 'mlBlock'],
              }, this)}
            >
              <Copy embedded raw >{description}</Copy>
            </div>
            {!asTeaser && this.renderSelector(true, ['hidden', 'lBlock'])}
            {!asTeaser && this.renderSelector(false, ['hidden', 'smBlock'])}
          </div>
          <div className={suitcss({ descendantName: 'subContent' }, this)}>
            {isAvailable && this.renderPrices()}
            {!asTeaser && isAvailable && !isContractRenewal && this.renderButtons(['sHidden'])}
            {asTeaser && accessory && this.renderAccessory(['sHidden'])}
            {(selectedHardware.orderState === ORDER_STATE_UNAVAILABLE) &&
              <TextMessage>{ui.guiOrderState0}</TextMessage>
            }
          </div>
        </div>
        {!asTeaser && isAvailable && !isContractRenewal && this.renderButtons(['mlHidden'])}
        {asTeaser && (
          <div
            className={suitcss({
              descendantName: 'description',
              utilities: ['hidden', 'sBlock'],
            }, this)}
          >
            <Copy embedded raw >{description}</Copy>
          </div>
        )}
        {asTeaser && accessory && this.renderAccessory(['mlHidden'])}
      </div>
    );
  }
}

HardwareItemFull.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selectedHardware: hardwareShape,
  selectedTariffId: PropTypes.string,
  hardwareList: hardwareListShape.isRequired,
  isContractRenewal: PropTypes.bool,
  primaryModule: PropTypes.bool,
  onBeforeSelect: PropTypes.func,
  urlSelect: PropTypes.string,
  onChange: PropTypes.func,
  onContractRenewal: PropTypes.func,
  onToggleGallery: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    guiAddCart: PropTypes.string.isRequired,
    guiWordContinue: PropTypes.string.isRequired,
    guiContractRenewal: PropTypes.string.isRequired,
    guiVvlUrl: PropTypes.string.isRequired,
    hdeViewGallery: PropTypes.string.isRequired,
    guiWordStartingFrom: PropTypes.string,
    guiWordSingular: PropTypes.string,
    guiFeeExtraMonthlyAbbr: PropTypes.string,
    guiWordFreeShipping: PropTypes.string.isRequired,
    myVvlButtonStart: PropTypes.string.isRequired,
    guiButtonWhatsapp: PropTypes.string.isRequired,
    guiShippingCosts: PropTypes.string,
    guiShippingCostsInfoIAlt: PropTypes.string,
  }),
  accessory: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.object,
  }),
  asTeaser: PropTypes.bool,
  reviewActivated: PropTypes.bool,
  isNew: PropTypes.bool,
  dispatch: PropTypes.func,
  shippingFee: PropTypes.object,
};

HardwareItemFull.defaultProps = {};

const mapDispatchToProps = dispatch => ({ dispatch });

export default compose(
  connect(mapDispatchToProps),
  connectUI(),
)(HardwareItemFull);
