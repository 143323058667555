/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import suitcss from '../../../helpers/suitcss';
import { imagePropTypes } from '../../../propTypes/media';
import IntersectionObserver from '../service/IntersectionObserver';

/**
 * Component for displaying images with img-Tag
 * @param  {string} options.alt        Alternative text
 * @param  {object} options.variations An object of source variations to use in a srcset
 *                                     attribute. Keys are the sizes, values the sources.
 *                                     The smallest defined key will automatically prepresent the
 *                                     default src attribute.
 *                                     E.g
 *                                     { '100w': '/my/100w/path.jpg', '200w': '/my/200w/path.jpg' }
 * @param  {object}  options.sizes     Sizes for different media queries the component will appear
 *                                     in. Keys are the media queries, values the according sizes.
 *                                     E.g.
 *                                     { '(min-width: 36em)': '33.3vw', '': '100vw' }
 * @param  {string} options.src        Source path to a single image resource. This will only be
 *                                     used when no variants was found.
 */
class MediaImage extends PureComponent {

  static getSortedVariations = (variations) => (
    Object.entries(variations || {}).sort(
      // sort by size ascending
      ([sizeA], [sizeB]) => (parseInt(sizeA, 10) < parseInt(sizeB, 10) ? -1 : 1),
    )
  );

  static getSortedSizes = (sizes) => (
    // sort media, empty media last
    Object.entries(sizes || {}).sort(([mediaA], [mediaB]) => {
      if (!mediaA && !mediaB) {
        return 0;
      }
      return !mediaA ? 1 : -1;
    })
  );

  static getSrcSet = (variations, src, width) => {
    const original = (src && width) ? { [`${width}w`]: src } : {};
    const sortedSrcEntries = MediaImage.getSortedVariations({ ...variations, ...original });
    const srcSet = sortedSrcEntries.map(
      ([size, variationSrc]) => `${variationSrc} ${size}`,
    );
    return srcSet.join(', ') || null;
  };

  static getSizes = (sizes) => {
    const sizeList = MediaImage.getSortedSizes(sizes || {}).map(([media, size]) => `${media} ${size}`.trim());
    // 100vw is the default in html spec, we just return it for unknown browser quirks and to
    // support the developer who is not aware of the spec
    return sizeList.join(', ').trim() || '100vw';
  };

  static getDefaultSource = (variations, fallback) => {
    const sortedSrcEntries = MediaImage.getSortedVariations(variations || {});
    return sortedSrcEntries[0] ? sortedSrcEntries[0][1] : fallback;
  };

  static getPlaceholderSource(width, height) {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}" %3E%3C/svg%3E`;
  }

  constructor(props, context) {
    super(props, context);
    this.imgRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.state = {
      isIntersecting: false,
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { isLazy } = this.props;
    if (!isLazy) {
      this.applyUpdates();
    }
  }

  componentDidUpdate() {
    this.applyUpdates(true);
  }

  onChange(ev, unobserve) {
    const { isIntersecting } = ev;
    if (isIntersecting) {
      unobserve();
      this.setState({
        isIntersecting: true,
      });
    }
  }

  onLoad() {
    this.setState({ isLoaded: true });
  }

  applyUpdates(reevaluate = false) {
    if (window.picturefill) {
      window.picturefill({ elements: [this.imgRef.current], reevaluate });
    }
  }

  renderImage() {
    const {
      src,
      sizes,
      variations,
      width,
      height,
      alt,
      isLazy,
      emptyAlt,
    } = this.props;

    const { isIntersecting } = this.state;

    if (!isLazy) {
      return (
        <img
          srcSet={MediaImage.getSrcSet(variations, src, width)}
          sizes={MediaImage.getSizes(sizes)}
          src={MediaImage.getDefaultSource(variations, src)}
          alt={emptyAlt ? '' : alt}
          ref={this.imgRef}
        />
      );
    }

    return (
      <IntersectionObserver rootMargin="0px -10px 0px -10px" onChange={this.onChange}>
        <img
          srcSet={isIntersecting ? MediaImage.getSrcSet(variations, src, width) : null}
          sizes={isIntersecting ? MediaImage.getSizes(sizes) : null}
          src={MediaImage.getPlaceholderSource(width, height)}
          alt={emptyAlt ? '' : alt}
          ref={this.imgRef}
          onLoad={isIntersecting ? this.onLoad : null}
        />
      </IntersectionObserver>
    );
  }

  render() {
    const { element: Element, isLazy } = this.props;
    const { isLoaded } = this.state;
    return (
      <Element
        className={suitcss({
          componentName: 'MediaImage',
          states: [isLazy && 'lazy', isLazy && isLoaded && 'loaded'],
        })}
      >
        {this.renderImage()}
      </Element>
    );
  }
}

MediaImage.propTypes = {
  ...imagePropTypes,
  emptyAlt: PropTypes.bool,
};
MediaImage.defaultProps = {
  element: 'span',
  variations: {},
  sizes: {},
  isLazy: false,
  emptyAlt: false,
};

export default MediaImage;
