import React from 'react';
import PropTypes from 'prop-types';
import oneLine from 'common-tags/lib/oneLine';
import suitcss from '../../../helpers/suitcss';

const componentName = 'TextPlain';
const TextPlain = ({ children, element }) => {
  const Element = element;
  const plainText = children.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <Element
      className={suitcss({ componentName })}
      dangerouslySetInnerHTML={{
        __html: oneLine`${plainText}`,
      }}
    />
  );
};

TextPlain.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.string,
};

TextPlain.defaultProps = {
  element: 'p',
};
export default TextPlain;
