import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import connectUI from '../../basics/ui/UIConnector';

import { list as downloadList } from '../../../propTypes/download';

import Link from '../../basics/text/TextLink';
import Headline from '../../basics/text/TextHeadline';
import DownloadListItem from './DownloadListItem';
import ListAccordion from '../../basics/accordion/ListAccordion';

const componentName = 'DownloadList';
class DownloadList extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      totalItems: props.itemsPerView === 0 ? props.items.length : props.itemsPerView,
    };

    this.onShowMore = this.onShowMore.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  onShowMore() {
    const { items, itemsPerView } = this.props;
    const { totalItems } = this.state;
    const nextItemCount = totalItems + itemsPerView;
    this.setState({
      totalItems: nextItemCount >= items.length ? items.length : nextItemCount,
    });
  }

  renderHeadline() {
    const { headline } = this.props;
    return headline && (
      <Headline
        className={suitcss({ componentName, descendantName: 'headline' })}
        size="l"
        embedded
        element="h2"
      >
        {headline}
      </Headline>
    );
  }

  renderShowMoreButton() {
    const { totalItems } = this.state;
    const { items, ui } = this.props;
    return totalItems < items.length && (
      <Link
        className={suitcss({ componentName, descendantName: 'link' })}
        element="button"
        onClick={this.onShowMore}
      >
        {ui.guiShowMoreContent}
      </Link>
    );
  }

  renderItem({ item, index }) {
    const isSubList = item.entities && item.entities.length;

    return !isSubList ? (
      <DownloadListItem
        date={item.date}
        copy={item.copy}
        file={item.file}
        key={index}
      />
    ) : (
      <ListAccordion
        accordionItems={item}
        wrapperElement="li"
        renderChildElements={this.renderItem}
        key={index}
      />
    );
  }

  render() {
    const { items } = this.props;
    const { totalItems } = this.state;
    // trim items to only show the amount of items specified in totalItems
    const trimmedItems = items.slice(0, totalItems);
    return (
      <div className={suitcss({ componentName })}>
        {this.renderHeadline()}
        <ul
          className={suitcss({
            componentName,
            descendantName: 'list',
          })}
        >
          {trimmedItems.map((item, index) =>
            this.renderItem({ item, index }))}
        </ul>
        {this.renderShowMoreButton()}
      </div>
    );
  }
}

DownloadList.propTypes = {
  headline: PropTypes.string,
  items: downloadList,
  itemsPerView: PropTypes.number,
  ui: PropTypes.shape({
    guiShowMoreContent: PropTypes.string.isRequired,
  }),
};

export default connectUI()(DownloadList);
