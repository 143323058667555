import React from 'react';
import PropTypes from 'prop-types';
import { shape as hardwareShape } from '../../../propTypes/hardware';
import connectUI from '../../basics/ui/UIConnector';


const HardwareDeliveryState = ({
  selectedHardware,
  ui,
}) => {
  const stateTemplate = ui[`guiDeliveryState${selectedHardware.deliveryState}`];
  const deliverStateText = stateTemplate.replace('{DELIVERY_TIME}', selectedHardware.deliveryTime);
  return (
    <>{deliverStateText}</>
  );
};

HardwareDeliveryState.propTypes = {
  selectedHardware: hardwareShape,
  ui: PropTypes.shape({
    guiDeliveryState1: PropTypes.string.isRequired,
    guiDeliveryState2: PropTypes.string.isRequired,
    guiDeliveryState3: PropTypes.string.isRequired,
    guiDeliveryState4: PropTypes.string.isRequired,
    guiDeliveryState5: PropTypes.string.isRequired,
  }),
};

export default connectUI()(HardwareDeliveryState);
