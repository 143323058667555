import React from 'react';
import suitcss from '../../../helpers/suitcss';
import Slider from '../slider/Slider';
import SliderSlide from '../slider/SliderSlide';
import MediaImage from './MediaImage';

import { imageList } from '../../../propTypes/media';
import imageSizes from '../../../config/imageSizes';

function MediaImageGallery(props) {
  return (
    <div className={suitcss({ componentName: 'MediaImageGallery' })}>
      <Slider arrows pagination config={{ slidesPerView: 1, loop: false }}>
        {props.images.map((img, index) => (
          <SliderSlide key={index}>
            <MediaImage {...img} sizes={imageSizes.mediaGalleryImage} isLazy emptyAlt />
          </SliderSlide>
        ))}
      </Slider>
    </div>
  );
}

MediaImageGallery.propTypes = {
  images: imageList.isRequired,
};

export default MediaImageGallery;
