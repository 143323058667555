import React, { useState } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import ListAccordionItem from './ListAccordionItem';

const componentName = 'ListAccordion';
const ListAccordion = ({ accordionItems, wrapperElement, renderChildElements }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const Element = wrapperElement;
  const itemArray = Array.isArray(accordionItems) ? accordionItems : [accordionItems];

  return (
    <Element className={suitcss({ componentName })}>
      {itemArray.map((accItem, index) => (
        <ListAccordionItem
          key={`accordion-item-${index}`}
          renderChildElements={renderChildElements}
          accItem={accItem}
          index={index}
          openIndex={openIndex}
          toggleAccordion={toggleAccordion}
        />
      ))}
    </Element>
  );
};

ListAccordion.propTypes = {
  accordionItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  wrapperElement: PropTypes.string,
  renderChildElements: PropTypes.func.isRequired,
};

ListAccordion.defaultProps = {
  wrapperElement: 'div',
};

export default ListAccordion;
