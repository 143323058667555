import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

/**
 * @deprecated This component is deprecated and should not be used anymore.
 * To enshure an accessible design you should use the ListAccordion component.
 *
 * @param children
 * @param modifiers
 * @returns {JSX.Element}
 * @constructor
 */
const Accordion = ({ children, modifiers }) => (
  <div className={suitcss({ componentName: 'Accordion', modifiers })}>
    {children}
  </div>
);

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  modifiers: PropTypes.array,
};

export default Accordion;
