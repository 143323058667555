import { PropTypes } from 'prop-types';
import { camelCaseToKebabCase } from './str';

export const ariaPropsTypes = {
  ariaControls: PropTypes.string,
};

export const ariaHtmlAttributes = (props) => Object.keys(props)
  .filter(key => key.startsWith('aria'))
  .reduce((acc, key) => {
    const htmlKey = camelCaseToKebabCase(key);
    acc[htmlKey] = props[key];
    return acc;
  }, {});

export const ariaAttributes = (props) => Object.keys(props)
  .filter(key => key.startsWith('aria'))
  .reduce((acc, key) => {
    acc[key] = props[key];
    return acc;
  }, {});
