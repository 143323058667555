import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const componentName = 'ListAccordionItem';
const ListAccordionItem = (
  {
     index,
     openIndex,
     toggleAccordion,
     accItem,
     renderChildElements,
  },
  ) => {
  const handleKeyPress = (e, itemIdx) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleAccordion(itemIdx);
    }
  };

  const isOpen = openIndex === index;
  const buttonId = `accordion-button-${index}`;
  const panelId = `accordion-panel-${index}`;

  return (
    <>
      <h3 className={suitcss({ componentName, descendantName: 'headline' })}>
        <button
          type="button"
          id={buttonId}
          aria-expanded={isOpen}
          aria-controls={panelId}
          onClick={() => toggleAccordion(index)}
          onKeyDown={(e) => handleKeyPress(e, index)}
        >
          <span className={suitcss({ componentName, descendantName: 'label' })}>
            {accItem.headline}
          </span>
        </button>
      </h3>
      <ul
        className={suitcss({
          componentName,
          descendantName: 'list',
          modifiers: [isOpen && 'open'],
        })}
        id={panelId}
        role="region"
        aria-labelledby={buttonId}
        hidden={!isOpen}
      >
        {accItem.entities.map((item, idx) =>
          renderChildElements({ item, index: idx }))}
      </ul>
    </>
  );
};

ListAccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  openIndex: PropTypes.number.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  accItem: PropTypes.object.isRequired,
  renderChildElements: PropTypes.func.isRequired,
};
export default ListAccordionItem;
