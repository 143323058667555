import PageModule from './PageModule';

/**
 * This module is used to split the display with contents as images, texts, headlines
 */
class ContentGroupSplitScreenModule extends PageModule {
  constructor(moduleData) {
    super(import('../../containers/content/ContentGroupSplitScreen'), moduleData);
  }

  /**
   * @override
   */
  static isCritical() {
    return false;
  }
}

export default ContentGroupSplitScreenModule;
