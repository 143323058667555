import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

const MediaSvgData = ({
    inverted, utilities, emptyAriaLabel, data,
   }) => (
     <span
       className={suitcss({
         componentName: 'Icon',
         modifiers: [
           inverted && 'inverted',
         ],
         utilities,
       })}
       {...(emptyAriaLabel ? { 'aria-label': '' } : {})}
       dangerouslySetInnerHTML={{ __html: data }}
     />
);

MediaSvgData.propTypes = {
  inverted: PropTypes.bool,
  utilities: PropTypes.array,
  data: PropTypes.string,
  emptyAriaLabel: PropTypes.bool,
};

export default MediaSvgData;
