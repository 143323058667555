/* global window */
import camelcase from 'camelcase';
import decamelcase from 'decamelize';
import mapObj from 'map-obj';

const NON_ALPHANUM = /[^a-zA-Z0-9]/g;

export const capitalize = str =>
  str[0].toUpperCase() + str.slice(1);

export const camelCase = (str, options = { keepDash: true }) => {
  if (str.includes('-') && options.keepDash) {
    const fragments = str.split('-');
    return fragments.map((fragment) => camelcase(fragment)).join('-');
  }
  return camelcase(str);
};

export const decamelize = str =>
  decamelcase(str);

/**
 * Converts the format of the keys of a given object to camel case. If it's an array it
 * maps the function to all elements.
 * @param obj The given object
 * @returns {opts} The camelized key object
 */
export const camelizeObjectKeys = obj => {
  if (Array.isArray(obj)) return obj.map(camelizeObjectKeys);
  return mapObj(obj, (key, val) => [camelCase(key), val], { deep: true });
};

/**
 * Converts the format of the keys of a given object to snake case
 * @param obj The given object
 * @returns {opts} The camelized key object
 */
export const decamelizeObjectKeys = obj =>
  mapObj(obj, (key, val) => [decamelize(key), val], { deep: true });

export const pascalCase = str =>
  capitalize(camelcase(str));

export const prefixNumber = (str, prefix) =>
  str.replace(/([0-9]+)/g, `${prefix}$1`);

export const stripHTML = (str, tagName) => {
  // if no tagName is given remove all tags
  if (!tagName) {
    return str.replace(/<(?:.|\n)*?>/gm, '');
  }
  return str.replace(new RegExp(`</?${tagName}[^>]*>`, 'gm'), '');
};

/**
 * Removes the non alphanumeric characters of a string and therefore desegmentizes it.
 * @param str The string to desegmentize.
 * @return The desegmentized string.
 */
export const desegmentize = (str = '') => str.replace(NON_ALPHANUM, '').toUpperCase();

/**
 * Separates a string into segments of a given size.
 * @param str The string to segmentize.
 * @param digits The number of digits to group. The standard value is 4.
 * @return The seqmentized string.
 */
export const segmentize = (str = '', digits = 4) => {
  const regStr = `(.{${digits}})(?!$)`;
  const regEx = new RegExp(regStr, 'g');
  return str.replace(regEx, '$1 ');
};

export const segmentize4Digits = (str) => segmentize(str, 4);

/**
 * Converts a string to base64 encoding.
 *
 * To do so, we either choose the native btoa browser function or the Buffer class
 * received via scope.
 *
 * @param {string} str
 */
export const base64 = str =>
  (process.browser ? window.btoa(str) : (new Buffer(str)).toString('base64'));

export const sanitizeString = (str, furtherReplacements = {}) => {
  const ESC_MAP = {
    '<': '',
    '>': '',
    '%3C': '',
    '%3E': '',
    '&#60': '',
    '&#62': '',
    '"': '&quot;',
    "'": '&#x27;', // eslint-disable-line
  };

  const replacements = { ...ESC_MAP, ...furtherReplacements };

  return str.replace(/[<>"']|%3C|%3E|&#60|&#62/ig, (match) => (replacements[match]));
};

export const stringIsBoolTrue = str => str === 'true';

export const camelCaseToKebabCase = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};
