import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import suitcss from '../../../helpers/suitcss';
import {
  toIntegerDigits,
  toFractionSeperator,
  toFractionDigits,
  toDecimal,
  toCurrencySymbol,
  toAbsolute,
} from '../../../helpers/money';
import { priceShape } from '../../../propTypes/unit';

const springConfig = { stiffness: 600, damping: 40 };

class TextUnit extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      unit: (props.prefixPrice && props.prefixPrice.unit) || props.price.unit,
    };
  }

  renderPostfix(prefixPrice) {
    const { showCurrency, price, postfix } = this.props;
    const isArray = Array.isArray(postfix);
    return (
      <span className={suitcss({ descendantName: 'postfix', utilities: [prefixPrice && 'colorTertiary'] }, this)}>
        {showCurrency && `${toCurrencySymbol(price)} `}
        { isArray && postfix.map((el, i) => (<span key={i}>{el}</span>))}
        { !isArray && (<span className={suitcss({ utilities: [prefixPrice && 'colorDefault'] })}>{postfix}</span>)}
      </span>
    );
  }

  renderPrefix() {
    const { prefix, prefixPrice, wrap } = this.props;
    return (
      <React.Fragment>
        {prefix &&
          <span className={suitcss({ descendantName: 'prefix' }, this)}>
            {prefix}
          </span>
        }
        {prefixPrice &&
          <span className={suitcss({ descendantName: 'prefixPrice', utilities: ['strikethrough'] }, this)}>
            {toDecimal(prefixPrice)}
            {wrap && this.renderPostfix()}
          </span>
        }
      </React.Fragment>
    );
  }

  renderBlock(price) {
    const { priceTheme, prefixPrice } = this.props;
    const utilities = [
      prefixPrice && priceTheme === 'tertiary' && 'colorTertiary',
    ];

    return (
      <span className={suitcss({ utilities }, this)}>
        <span className={suitcss({ descendantName: 'unit' }, this)}>
          {toIntegerDigits(price)}
        </span>
        <span className={suitcss({ descendantName: 'seperator' }, this)}>
          {toFractionSeperator(price)}
        </span>
        <span className={suitcss({ descendantName: 'raised' }, this)}>
          <span className={suitcss({ descendantName: 'subunit' }, this)}>
            {toFractionDigits(toAbsolute(price))}
          </span>
          {this.renderPostfix()}
        </span>
      </span>
    );
  }

  renderText(price) {
    const { prefixPrice, prefix, showCurrency } = this.props;

    return `
        ${prefix ?? ''} ${prefixPrice ?? ''}
        ${toIntegerDigits(price)}${toFractionSeperator(price)}${toFractionDigits(toAbsolute(price))}
        ${showCurrency ? toCurrencySymbol(price) : ''}
      `;
  }

  renderInline(price) {
    return (
      <span>
        <span className={suitcss({ descendantName: 'unit' }, this)}>
          {toDecimal(price)}
        </span>
        {this.renderPostfix()}
      </span>
    );
  }

  renderContent(price, ariaHidden = false) {
    const { prefix, prefixPrice, inline, inverted, bold, wrap } = this.props;
    const size = this.props.size || null;
    const modifiers = [
      size,
      wrap && 'wrap',
    ];
    const utilities = [
      inverted && 'colorInverted',
      bold && 'weightBold',
      ...this.props.utilities,
    ];


    return (
      <span
        aria-hidden={ariaHidden}
        className={
          suitcss({
            modifiers,
            utilities,
          }, this)}
      >
        {(prefix || prefixPrice) && this.renderPrefix()}
        {inline && this.renderInline(price)}
        {!inline && this.renderBlock(price)}
      </span>
    );
  }

  render() {
    const { price, withoutTransition } = this.props;

    if (withoutTransition) {
      return this.renderContent(price);
    }

    const { unit, currency } = price;
    const defaultStyle = { unit: this.state.unit };
    const style = { unit: spring(unit, springConfig) };
    return (
      <>
        <Motion defaultStyle={defaultStyle} style={style}>
          {value => this.renderContent({ unit: Math.round(value.unit), currency }, true)}
        </Motion>
        <span className="sr-only">{this.renderText(price)}</span>
      </>
    );
  }
}

TextUnit.propTypes = {
  priceTheme: PropTypes.oneOf([false, null, 'tertiary']),
  prefixPrice: PropTypes.shape({
    unit: PropTypes.number,
  }),
  withoutTransition: PropTypes.bool,
  postfix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  price: priceShape.isRequired,
  prefix: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 's', 'm', 'l']),
  showCurrency: PropTypes.bool,
  inverted: PropTypes.bool,
  bold: PropTypes.bool,
  utilities: PropTypes.array,
  inline: PropTypes.bool,
  wrap: PropTypes.bool,
};

TextUnit.defaultProps = {
  priceTheme: null,
  showCurrency: true,
  inverted: false,
  bold: true,
  size: 'inherit',
  utilities: [],
};

export default TextUnit;
