import intlDt from 'intl-dt';
import {
  CONTRACT_RENEWAL_WITH_HARDWARE, CONTRACT_RENEWAL_WITHOUT_HARDWARE, CONTRACT_TARIFF_CHANGE,
} from './constants';

export const SECOND = 1000;
export const MINUTE = 60 * 1000;
export const HOUR = 60 * 60 * 1000;
export const DAY = 60 * 60 * 24 * 1000;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 360;

export const after24Hours = () => new Date(Date.now() + DAY);

export const formatDate = (date, style = 'medium') =>
  // to ensure that the formatted date is the given date
  // even if the user location is different
  new Date(date).toLocaleDateString('de-DE', {
    dateStyle: style,
    timeZone: 'Europe/Berlin',
  });

export const formatCreditCardExpirationDate = (date) =>
  intlDt(new Date(date), 'de-DE').format('MM/YYYY');

export const formatTime = (date) =>
  intlDt(new Date(date), 'de-DE').format('H:mm');

export const formatTimeHHMMSS = (date) =>
  intlDt(new Date(date), 'de-DE').format('HH:mm:ss');

/**
 * Formats a timespan to a colon separated string in the scheme HH:mm:ss
 * Hours can be more than 24
 * @param timespan The time to be formatted in milliseconds
 * @returns {string} The formatted string
 */
const timespanToHHMMSS = (milliseconds) => {
  const seconds = Math.floor(milliseconds / SECOND) % 60;
  const minutes = Math.floor(milliseconds / MINUTE) % 60;
  const hours = Math.floor(milliseconds / HOUR);
  return [hours, minutes, seconds].map(value => (value < 10 ? `0${value}` : value)).join(':');
};
/**
 * Formats the time as a function of the remaining time. If there are more than
 * 2 days left the output is 'x Tage'. Else the output is in the format HH:mm:ss
 * @param timeSpan A timespan in milliseconds
 * @returns {string} The remaining formated time
 */
export const getRemainingTimeFormated = (remainingTime) => {
  const remainingDays = Math.ceil(remainingTime / DAY);
  if (remainingDays > 2) {
    return `${remainingDays} Tage`;
  }
  return timespanToHHMMSS(remainingTime);
};

export const getTariffVoDate = (tariffVo, type) => {
  let isContractRenewal;
  let isWithHardware;
  switch (type) {
    case CONTRACT_RENEWAL_WITH_HARDWARE:
      isContractRenewal = true;
      isWithHardware = true;
      break;
    case CONTRACT_RENEWAL_WITHOUT_HARDWARE:
      isContractRenewal = true;
      isWithHardware = false;
      break;
    case CONTRACT_TARIFF_CHANGE:
      isContractRenewal = false;
      isWithHardware = false;
      break;
  }

  const filteredCondition = tariffVo.availableConditions.filter((condition) =>
    condition.includesHardware === isWithHardware &&
    condition.requiresContractRenewal === isContractRenewal);
  return filteredCondition.length ? filteredCondition[0].realizationDate : tariffVo.stateDate;
};
