import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';

import connectUniqueId from '../service/UniqueIdConnector';

class FormOptionGroup extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = {
      label: this.props.label,
      isHovered: false,
      isHoveredDisabled: false,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (!nextState.isHovered) {
      this.setState({ label: nextProps.label });
    }
  }

  onMouseEnter(value, disabled) {
    this.setState({ label: value, isHovered: true, isHoveredDisabled: disabled });
  }

  onMouseLeave() {
    this.setState({ label: this.props.label, isHovered: false, isHoveredDisabled: false });
  }

  renderChild(child, index) {
    if (!React.isValidElement(child)) return child;

    const { selectedValue, name, onChange, uniqueId } = this.props;
    const childProps = { id: `${name}_${uniqueId}_${index}`, name: `${name}` };

    childProps.checked = (child.props.value === selectedValue || child.props.checked);
    childProps.onChange = onChange;

    if (this.props.showLabel) {
      childProps.onMouseEnter = () => this.onMouseEnter(child.props.label, child.props.disabled);
      childProps.onMouseLeave = () => this.onMouseLeave(child.props.label);
    }

    return React.cloneElement(child, childProps);
  }

  render() {
    const children = React.Children.map(this.props.children, this.renderChild, this);

    return (
      <div
        className={suitcss({
          modifiers: this.props.modifiers,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'content',
          }, this)}
        >
          {children}
        </div>
        {this.props.showLabel && (
          <p
            aria-hidden
            className={suitcss({
              descendantName: 'label',
              states: [this.state.isHoveredDisabled && 'disabled'],
            }, this)}
          >{this.state.label}</p>
        )}
      </div>
    );
  }
}

FormOptionGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  showLabel: PropTypes.bool,
  onChange: PropTypes.func,
  modifiers: PropTypes.array,
  children: PropTypes.arrayOf(PropTypes.element),
  uniqueId: PropTypes.string,
};

FormOptionGroup.defaultProps = {
  showLabel: false,
  selectedValue: false,
};

export default connectUniqueId('fog')(FormOptionGroup);
