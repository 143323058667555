import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import suitcss from '../../../helpers/suitcss';

import { shape as hardwareShape, list as hardwareListShape } from '../../../propTypes/hardware';

import Copy from '../../basics/text/TextCopy';
import FormSelectBox from '../../basics/form/FormSelectBox';
import FormSelectBoxOption from '../../basics/form/FormSelectBoxOption';
import FormOptionGroup from '../../basics/form/FormOptionGroup';
import FormOptionTab from '../../basics/form/FormOptionTab';
import FormOptionBullet from '../../basics/form/FormOptionBullet';

import connectUI from '../../basics/ui/UIConnector';
import connectUniqueId from '../../basics/service/UniqueIdConnector';

import { ORDER_STATE_UNAVAILABLE } from '../../../propTypes/common';
import { ariaAttributes } from '../../../helpers/ariaAttributes';

class HardwareSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.onHardwareOptionsChange = this.onHardwareOptionsChange.bind(this);
  }

  onHardwareOptionsChange(type, event) {
    if (this.props.onUserInteraction) {
      this.props.onUserInteraction();
    }

    return this.onSettingsChange(type, event);
  }

  onSettingsChange(type, value) {
    const { selectedHardware, onChange } = this.props;
    const selectedSettingsItem = this.getSettingsListOfType(type).find(
      option => option.value === value,
    );

    let nextSelectedHardwareIdentifiers;
    switch (type) {
      case 'color':
        nextSelectedHardwareIdentifiers = {
          capacity: selectedHardware.capacity,
          colorCode: selectedSettingsItem.value,
        };
        break;

      case 'capacity':
        nextSelectedHardwareIdentifiers = {
          capacity: selectedSettingsItem.value,
          colorCode: selectedHardware.colorCode,
        };
        break;

      default:
        throw new TypeError('´type´ must be ´color´ or ´capacity´');
    }
    const nextSelectedHardware = this.getAvailbleHardwareBasedOn(
      nextSelectedHardwareIdentifiers,
    );

    onChange(nextSelectedHardware);
  }

  getSortedHardwareList(key) {
    const { hardwareList } = this.props;
    return hardwareList.sort((a, b) => a[key].toString().localeCompare(b[key].toString()));
  }

  // return nextSelectedHardware based on settings set
  // return next best if settings combination is not available
  getAvailbleHardwareBasedOn({ capacity, colorCode }) {
    const { hardwareList } = this.props;

    if (!this.isSettingAvailable('capacity', capacity)) {
      return this.getSortedHardwareList('color').find(item =>
        item.capacity.toString() === capacity.toString());
    }

    if (!this.isSettingAvailable('colorCode', colorCode)) {
      return this.getSortedHardwareList('capacity').find(item =>
        item.colorCode.toString() === colorCode.toString());
    }

    return hardwareList.find(item => (
      item.capacity.toString() === capacity.toString()
      && item.colorCode.toString() === colorCode.toString()
    ));
  }

  getSelectedValueOfType(type) {
    const { selectedHardware } = this.props;
    return type === 'color'
      ? selectedHardware.colorCode.toString()
      : selectedHardware[type].toString();
  }

  getSettingsItemOfType({ type, item, disabled }) {
    const { ui } = this.props;
    return {
      disabled,
      label: type === 'capacity'
        ? `${item[type]} ${ui.guiSymbolGigabyte}`
        : item[type].toString(),
      value: type === 'color'
        ? item.colorCode.toString()
        : item[type].toString(),
      dataHardwareId: item.iid,
    };
  }

  /**
   * Returns a list of all values of a spezific type from the harware items where
   * every value is unique
   * @param  {string} type Key within the hardware items
   * @param  {object} selectedHardware
   * @return {array}      List of values
   */
  getSettingsListOfType(type, selectedHardware = null) {
    const { hardwareList } = this.props;
    const availableType = type === 'color' ? 'colorCode' : type;

    /**
     * There can be multiple options for the same frontend value, e.g. the color "Pink"
     * can be for a 256GB and a 128GB capacity Phone.
     * Each option has its own hardwareId
     * to report the correct selection in tracking, we need to get the correct hardwareId
     * to the current selection
     * e.g. if the user has selected 256GB and clicks on pink,
     * we want to get the entry for 256/pink instead of 128/pink
     * @param toBeAdded
     * @param inMap
     * @returns {*}
     */
    const chooseBestMatchingToSelectedHardware = (toBeAdded, inMap) => {
      if (!selectedHardware) return toBeAdded;
      const optionB = hardwareList.find(v => v.iid === inMap?.dataHardwareId);
      if (!optionB) return toBeAdded;

      if (availableType === 'capacity') {
        if (optionB.colorCode === selectedHardware.colorCode) return inMap;
        return toBeAdded;
      }
      if (availableType === 'colorCode') {
        if (optionB.capacity === selectedHardware.capacity) return inMap;
        return toBeAdded;
      }
      return toBeAdded;
    };

    const valueMap = hardwareList.reduce((map, item) => {
      if (item[type]) {
        const disabled = !this.isSettingAvailable(availableType, item[availableType])
          || item.orderState === ORDER_STATE_UNAVAILABLE;
        const inMap = map.get(item[type]);
        const toBeAdded = this.getSettingsItemOfType({ type, item, disabled });
        const bestOption = chooseBestMatchingToSelectedHardware(toBeAdded, inMap);
        map.set(item[type], bestOption);
      }
      return map;
    }, new Map());

    return [...valueMap.values()].sort(type === 'color'
      ? (a, b) => a.label.localeCompare(b.label)
      : (a, b) => a.value > b.value);
  }

  // Check if settings combination is available based on SelectedHardware settings
  // Return true if settings combination is availble
  isSettingAvailable(type, value) {
    const { hardwareList, selectedHardware } = this.props;
    const basedOn = type === 'colorCode'
      ? { type: 'capacity', value: selectedHardware.capacity.toString() }
      : { type: 'colorCode', value: selectedHardware.colorCode.toString() };
    return hardwareList.some(
      item => item[basedOn.type].toString() === basedOn.value.toString()
        && item[type].toString() === value.toString(),
    );
  }

  renderOptionGroupOfType(type, showLabelValue) {
    const { selectedHardware } = this.props;
    const selectedValue = this.getSelectedValueOfType(type);
    const isColor = type === 'color';
    const renderAsBullet = isColor && !showLabelValue;
    return (
      <FormOptionGroup
        name={`${this.props.uniqueId}_${type}`}
        label={selectedHardware[type].toString()}
        showLabel={isColor && !showLabelValue}
        selectedValue={selectedValue}
        onChange={evt => this.onHardwareOptionsChange(type, evt.target.value)}
      >
        {this.getSettingsListOfType(type, selectedHardware).map((data, index) => {
          const hardwareTracking = {
            'hardware-id': data.dataHardwareId,
            'tracking-target': true,
            tracking: 'hardware',
          };
          return (
            renderAsBullet
              ? <FormOptionBullet
                  {...data}
                  key={index}
                  data={hardwareTracking}
                  {...ariaAttributes(this.props)}
              />
              : <FormOptionTab
                  {...data}
                  key={index}
                  withBullet={showLabelValue}
                  data={hardwareTracking}
                  {...ariaAttributes(this.props)}
              />
          );
        })}
      </FormOptionGroup>
    );
  }

  renderSelectBoxOfType(type) {
    const selectedValue = this.getSelectedValueOfType(type);
    const isColor = type === 'color';
    return (
      <FormSelectBox
        name={`${this.props.uniqueId}_${type}`}
        selectedValue={selectedValue}
        onChange={evt => this.onSettingsChange(type, evt.target.value)}
        valueAsBulletColor={isColor}
        size="m"
      >
        {this.getSettingsListOfType(type).map((data, index) => (
          <FormSelectBoxOption {...data} key={index} />
        ))}
      </FormSelectBox>
    );
  }

  renderCopy(text) {
    return (
      <div
        className={suitcss({
          descendantName: 'copy',
        }, this)}
      >
        <Copy embedded>{text}</Copy>
      </div>
    );
  }

  render() {
    const { theme, ui, inverted } = this.props;
    const modifiers = [theme];
    return (
      <div
        className={suitcss({
          modifiers,
        }, this)}
      >
        <div
          className={suitcss({
            descendantName: 'options',
          }, this)}
        >
          <div
            className={suitcss({
              descendantName: 'option',
            }, this)}
            data-tracking-target
          >
            {theme === 'full' && this.renderCopy(ui.hdeSelectCapacity)}
            {theme === 'full' && this.renderOptionGroupOfType('capacity', false)}
            {theme === 'compact' && this.renderSelectBoxOfType('capacity', inverted)}
            {theme === 'default' && this.renderOptionGroupOfType('capacity', false)}
          </div>
          <div
            className={suitcss({
              descendantName: 'option',
            }, this)}
            data-tracking-target
          >
            {theme === 'full' && this.renderCopy(ui.hdeSelectColor)}
            {theme === 'full' && this.renderOptionGroupOfType('color', true)}
            {theme === 'compact' && this.renderSelectBoxOfType('color', inverted)}
            {theme === 'default' && this.renderOptionGroupOfType('color', false)}
          </div>
        </div>
      </div>
    );
  }
}

HardwareSelector.propTypes = {
  theme: PropTypes.oneOf(['default', 'full', 'compact']),
  hardwareList: hardwareListShape.isRequired,
  selectedHardware: hardwareShape.isRequired,
  onChange: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  uniqueId: PropTypes.string.isRequired,
  ui: PropTypes.shape({
    hdeSelectCapacity: PropTypes.string,
    hdeSelectColor: PropTypes.string,
    guiSymbolGigabyte: PropTypes.string.isRequired,
  }),
  onUserInteraction: PropTypes.func,
  ariaControls: PropTypes.string,
};

HardwareSelector.defaultProps = {
  theme: 'default',
  inverted: false,
};

export default compose(
  connectUI(),
  connectUniqueId('hardware_selector'),
)(HardwareSelector);
