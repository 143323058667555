import React from 'react';
import PropTypes from 'prop-types';

import { list as downloadList } from '../../propTypes/download';
import GlobalSection from '../../components/basics/global/GlobalSection';
import DownloadListComposition from '../../components/compositions/download/DownloadList';

function DownloadList({ params, entities }) {
  return (
    <GlobalSection>
      <DownloadListComposition
        headline={params.headline}
        items={entities}
        itemsPerView={params.limit || 0}
      />
    </GlobalSection>
  );
}

DownloadList.propTypes = {
  params: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    limit: PropTypes.number,
  }),
  entities: downloadList.isRequired,
};

export default DownloadList;
