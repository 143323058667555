import React from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import suitcss from '../../../helpers/suitcss';
import { camelCase } from '../../../helpers/str';

import { linkShape } from '../../../propTypes/typography';
import { imageShape } from '../../../propTypes/media';
import imageSizes from '../../../config/imageSizes';

import SvgLoader from '../../basics/media/MediaSvgLoader';
import Headline from '../../basics/text/TextHeadline';
import Copy from '../../basics/text/TextCopy';
import Link from '../../basics/text/TextLink';
import MediaImageBackground from '../../basics/media/MediaImageBackground';
import MediaImage from '../../basics/media/MediaImage';

const sourcePlaceholder = '_SOURCE_';

function Teaser(props) {
  const {
    headline, copy, icon, image, link, inverted, type, layout, location,
  } = props;
  const isImageBanner = type === 'image_banner';
  const withBackgroundImage = !isImageBanner && image;
  const modifiers = [
    inverted && 'inverted',
    image && withBackgroundImage && 'background',
    type && camelCase(type),
  ];
  const { teaser: sizes } = imageSizes;
  const background = withBackgroundImage ? { ...image, sizes: sizes[layout] } : {};
  return (
    <MediaImageBackground className={suitcss({ componentName: 'Teaser', modifiers })} {...background} isLazy>
      {isImageBanner && image && !link.onClick &&
        <RouterLink className={suitcss({ componentName: 'Teaser', descendantName: 'image' })} to={link.url.replace(sourcePlaceholder, location.pathname)} target={link.target} rel={link.rel}>
          <MediaImage {...image} sizes={sizes[layout]} isLazy />
        </RouterLink>
      }
      {isImageBanner && image && link.onClick &&
        <div className={suitcss({ componentName: 'Teaser', descendantName: 'image' })} onClick={link.onClick} >
          <MediaImage {...image} sizes={sizes[layout]} isLazy />
        </div>
      }
      {!isImageBanner &&
        <div className={suitcss({ componentName: 'Teaser', descendantName: 'inner' })} >
          {icon &&
            <div className={suitcss({ componentName: 'Teaser', descendantName: 'icon', utilities: ['alignCenter'] })} >
              <SvgLoader path={icon} inverted={inverted} emptyAriaLabel />
            </div>
          }

          {headline &&
            <Headline size="l" utilities={['colorPrimary']} embedded raw >
              {headline}
            </Headline>
          }
          {copy &&
            <Copy utilities={[inverted && 'colorInverted']} embedded raw >
              {copy}
            </Copy>
          }
          {link &&
            <div className={suitcss({ componentName: 'Teaser', descendantName: 'link' })} >
              <Link
                to={link.url.replace(sourcePlaceholder, location.pathname)}
                asButton
                buttonFilled
              >
                {link.title}
              </Link>
            </div>
          }
        </div>
      }
    </MediaImageBackground>
  );
}

Teaser.propTypes = {
  type: PropTypes.oneOf(['text', 'icon_text', 'banner', 'image_banner']).isRequired,
  headline: PropTypes.string,
  copy: PropTypes.string,
  icon: PropTypes.string,
  image: imageShape,
  link: linkShape,
  layout: PropTypes.string,
  inverted: PropTypes.bool,
  location: PropTypes.object,
};

Teaser.defaultProps = {
  location: {},
};

export default Teaser;
